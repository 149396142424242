<template>
  <div class="vg_wrapper">
    <el-card>
      <div style="display: flex">
        <div class="vd_button_group vg_mtb_16">
          <el-radio-group v-model="radio" class="vg_mr_8" size="mini" @input="getDequsNow">
            <el-radio-button label="USD"></el-radio-button>
            <el-radio-button label="RMB"></el-radio-button>
          </el-radio-group>
          <el-date-picker
            v-model="dateRange"
            class="topDateRangeSearch vg_mr_8"
            end-placeholder="录入结束日期"
            range-separator="至"
            size="small"
            start-placeholder="录入开始日期"
            type="monthrange"
            value-format="timestamp"
          />
          <el-date-picker
            v-model="dateRange2"
            class="topDateRangeSearch vg_mr_8"
            end-placeholder="装运结束日期"
            range-separator="至"
            size="small"
            start-placeholder="装运开始日期"
            type="monthrange"
            value-format="timestamp"
          />
          <el-button icon="el-icon-search" size="small" type="primary" @click="getDequsNow()"> 查询</el-button>
          <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
          <el-button size="small" type="success" @click="exportToExcel('allTable')">导出到Excel</el-button>
        </div>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">金额累计: {{ accumulatedAmount.prod_total_price }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">数量累计: {{ accumulatedAmount.prod_num }}</div>
          </div>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`scon_prod_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getDequsNow"
      >
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { exorAPI } from '@api/modules/exor';
import helper from '@assets/js/helper.js';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { cloneDeep, debounce } from 'lodash';
import { downloadFile, setTime } from '@api/public';

export default {
  name: 'OureList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        {
          prop: 'prod_type',
          label: '种类',
          itemType: 'select',
          options: [
            { value: 1, label: 'Classic' },
            { value: 2, label: 'SuperChewer' },
            { value: 3, label: 'Home' }
          ],
          labelWidth: '120px',
          formatter: val => (val === 1 ? 'Classic' : val === 2 ? 'SuperChewer' : val === 3 ? 'Home' : '')
        },
        { prop: 'create_time', label: '录入日期', itemType: 'date', labelWidth: '120px', formatter: val => helper.toTimeDay(val), valueFormat: 'timestamp' },
        { prop: 'scon_cndate', label: '合同日期', itemType: 'date', labelWidth: '120px', formatter: val => helper.toTimeDay(val) },
        { prop: 'scon_no', label: '销售合同号', itemType: 'input', labelWidth: '120px' },
        { prop: 'cust_babbr', label: '最终客户', itemType: 'input', labelWidth: '120px' },
        { prop: 'cust_abbr', label: '客户简称', itemType: 'input', labelWidth: '120px' },
        { prop: 'scon_cust_no', label: '客户订单号', itemType: 'input', labelWidth: '120px' },
        { prop: 'scon_shdate', label: '装运日期', itemType: 'date', labelWidth: '120px', formatter: val => helper.toTimeDay(val) },
        { prop: 'cust_dport', label: '目的港', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_cust_no', label: '客户货号', itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_no', label: '我司货号', itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_poststfix', label: '货号后缀', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_ename', label: '英文品名', itemType: 'input', labelWidth: '120px' },
        { prop: 'cust_currency', label: '币种', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_usd', label: '单价', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_num', label: '数量', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'price', label: '金额', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_boxl', label: '长', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_boxw', label: '宽', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_boxh', label: '高', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'prod_nweight', label: '外箱净重', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'total_volume', label: '总体积', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'total_gross_weight', label: '总毛重', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'total_net_weight', label: '总净重', input: false, itemType: 'input', labelWidth: '120px' },
        { prop: 'cust_stff_name', label: '经办人', itemType: 'input', labelWidth: '120px' },
        { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px' }
      ],
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      dateRange: [],
      dateRange2: [],
      accumulatedAmount: {},
      radio: 'USD'
    };
  },
  created() {
    this.initData();
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    getDateNoTime,
    initData() {
      this.loadFlag = true;
      this.getDequsNow();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getStatsList();
    }, 1000),
    getStatsList() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.create_time = searchForm.create_time ? searchForm.create_time / 1000 : null;
      searchForm.cust_currency = this.radio;
      setTime(searchForm, 'start_time', 'end_time', this.dateRange);
      setTime(searchForm, 'start_time2', 'end_time2', this.dateRange2);
      getNoCatch(exorAPI.getExors, searchForm)
        .then(({ data }) => {
          this.tableData = data.list;
          this.totalPage = data.total;
          this.accumulatedAmount.prod_total_price = data.prod_total_price;
          this.accumulatedAmount.prod_num = data.prod_num;
          this.btn = data.btn;
          this.tableData.forEach(item => {
            //计算金额
            item.price = this.formatTotalPrice(item);
          });
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        })
        .catch(() => {});
    },
    // 查询方法
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.getStatsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 导出excel
    exportToExcel() {
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      exorAPI.exportExors(searchForm).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName });
      });
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.initData();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 计算金额
    formatTotalPrice(val) {
      return this.helper.haveFour(Number(val.prod_usd) * Number(val.prod_num));
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.searchForm.exor_sort = 'asc';
        this.searchForm.exor_field = val.prop;
      } else if (val.order == 'descending') {
        this.searchForm.exor_sort = 'desc';
        this.searchForm.exor_field = val.prop;
      } else {
        this.searchForm.exor_sort = '';
        this.searchForm.exor_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
